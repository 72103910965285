<template>
  <div id="newsPage">
    <app-navbar></app-navbar>
    <div class="smallYellowBrickBallone">
      <img class="img-fluid" src="@/assets/smallYellowBrickBallone.png" alt="yellowTwoBallonHorizont">
    </div>
    <div class="yellowTwoBallonHorizont">
      <img class="img-fluid" src="@/assets/yellowTwoBallonHorizont.png" alt="yellowTwoBallonHorizont">
    </div>
    <div class="crossBallone">
      <img class="img-fluid" src="@/assets/crossRedBallone.png" alt="whiteBallone">
    </div>
    <div class="box-yellow"></div>
    <div class="container newsBlock">
      <h2 class="label text-center">{{ "News.label" | staticTranslation }}</h2>
      <div class="col-lg-10 offset-lg-1 article-news box-shadow-3d mb-6 mb-lg-0" v-bind:key="article.id" v-for="article in news">
        <div v-if="article.title.en && isActiveEn || isActiveCs" class="row mb-lg-4">
          <div class="col-lg-5 img-hover-zoom">
            <img class="img-fluid cropped-img" :src="getImageUrl(article.id)" alt="Foto">
          </div>
          <div class="col-lg-8 pl-5 pb-4">
            <h4 class="pt-lg-4 pt-3">{{ article.created | date }}</h4>
            <h3>{{ article.title | translation }}</h3>
            <p class="crop-text">{{ article.preview | translation }}</p>
            <div>
              <a :href="'/news/detail/' + article.id">
                <div type="button" class="btn">{{ "News.button" | staticTranslation }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import axios from "@/api/axiom-instance";
import config from "@/config";

export default {
  name: "NewsPage",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  },
  data() {
    return {
      news: []
    }
  },
  created() {
    axios
        .get("/article/visible")
        .then((success) => {
          this.news = success.data
        })
    console.log(this.news)
  },
  methods: {
    getImageUrl: function (id) {
      return config.API_URL + '/image/preview?type=article-main&name=' + id
    }
  },

  computed: {
    isActiveCs: function () {
      return !(localStorage.getItem('lang') === 'en')
    },
    isActiveEn: function () {
      return (localStorage.getItem('lang') === 'en')
    }
  }


}
</script>

<style scoped>
h1 {
  font-size: 3.5em;
  font-family: "Transcript Pro";
}

h2 {
  padding-top: 4em;
  font-weight: bold;
  font-size: 2.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h3 {
  font-family: "Transcript Pro";
}

h4 {
  font-family: "Transcript Pro";
}

.crop-text {
  width: 100%;
  overflow: hidden;
  max-height: 16vh;
}

.label {
  padding-top: 3em;
}

p {
  font-family: "Transcript Mono";
}

#newsPage {
  background-color: #FFCE6D;
  z-index: 5;
}
.box-shadow-3d {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
.article-news {
  z-index: 3;
  background-color: #EAEAEA;
}

.row {
  margin-bottom: -17px;
}


.newsBlock {
  padding-bottom: 3em;
  min-height: 130vh;

}

.box-yellow {
  position: absolute;
  height: 130vh;
  z-index: 0;
  width: 70%;
  background-color: #ED5B2D;
}


.btn {
  padding: 4px 12px;
  border-radius: 0px;
  color: #152326;
  font-family: "Transcript Pro";
  background-color: #FFCE6D;
}

.btn:hover {
  background-color: #152326;
  color: #eae7d6;
}


.smallYellowBrickBallone {
  width: 13%;
  z-index: 1;
  position: absolute;
  left: 4%;
  top: 99vh;
}

.yellowTwoBallonHorizont {
  width: 13%;
  z-index: 1;
  position: absolute;
  left: 10%;
  top: 33%;
}

.crossBallone {
  width: 20%;
  position: absolute;
  top: 50%;
  text-align: end;
  right: 5%;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

@media (max-width: 380px){
  .box-yellow {
    display: none;
  }
  .cropped-img {
    max-width: 105%;
  }
  .img-hover-zoom {
    padding-left: 0;
    max-width: 800px;
  }
  .img-hover-zoom:hover img {
    transform: none;
  }
}
@media (min-width: 381px) and  (max-width: 480px) {
  .box-yellow {
    display: none;
  }
  .cropped-img {
    max-width: 103.5%;
  }
  .img-hover-zoom {
    padding-left: 0;
    max-width: 800px;
  }
  .img-hover-zoom:hover img {
    transform: none;
  }
}
@media (min-width: 481px) and (max-width: 799px){
  .img-hover-zoom {
    padding: 0;
  }

}
@media (min-width: 800px) {
  .cropped-img {
    height: 100%;
    width: auto;
    /*max-height: 280px;*/
    max-width: 250px;
    object-fit: cover;
  }
  .img-hover-zoom {
    padding-left: 0;
    max-width: 250px;
    overflow: hidden;
  }
  .img-hover-zoom:hover img {
    transform: scale(1.3);
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}
</style>
